import useAxios from './use-axios'
import serverClient from './serverClient'
import
axios,
{
  AxiosProgressEvent,
  AxiosResponse,
  CancelTokenSource
} from 'axios'

export const useGetOngoingProjects = () => useAxios(
  {
    url: '/ongoing-projects',
    method: 'GET'
  }
)

export const useGetClosedProjects = () => useAxios(
  {
    url: '/closed-projects',
    method: 'GET'
  }
)

export const useGetProject = (projectId: string) => useAxios({ url: `/project/${projectId}` })

export const useDelPhoto = (projectId: string, photoId: string) => useAxios(
  {
    url: `/project/${projectId}/photo/${photoId}`,
    method: 'DELETE'
  },
  { manual: true }
)

export const uploadPhoto = (
  image: File,
  projectId: string,
  setProgress: Function = null,
  delay: number = 0
): [Promise<AxiosResponse>, CancelTokenSource] => {
  const formData = new FormData()
  formData.append('file', image)

  const source = axios.CancelToken.source()
  const config = {
    params: {},
    cancelToken: source.token,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => setProgress && setProgress(
      Math.min(
        Math.round((100 * progressEvent.loaded) / progressEvent.total),
        100
      )
    )
  }

  return [
    new Promise<void>((resolve) => delay && setTimeout(resolve, delay * 1000) || resolve())
      .then(
        async () => await serverClient()
          .post(
            `/project/${projectId}/photo`,
            formData,
            config
          )
      ),
    source
  ]
}

export const useFinishEditing = (projectId: string) => useAxios(
  {
    url: `/project/${projectId}/deliver`,
    method: 'POST'
  },
  { manual: true }
)

export const useGetFinalFolderList = (projectId: string) => useAxios(
  { url: `/project/${projectId}/listFinalFolder` },
  { manual: true }
)

export const useGetEditorFolderList = (projectId: string) => useAxios(
  { url: `/project/${projectId}/listEditorFolder` }
)

export const useGetReadyReport = (projectId: string) => useAxios({ url: `/project/${projectId}/ready` })

export const useApplyEditing = (projectId: string) => useAxios(
  {
    url: `/project/${projectId}/apply`,
    method: 'POST'
  },
  { manual: true }
)
