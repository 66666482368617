
import { Box, Flex, Link, Text, useColorModeValue, Image } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin'

export default function AdminNavbar (props: {
  secondary: boolean
  message: string | boolean
  brandText: string
  logoText: string
  fixed: boolean
  onOpen: (...args: any[]) => any
}) {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', changeNavbar)

    return () => {
      window.removeEventListener('scroll', changeNavbar)
    }
  })

  const { secondary, brandText } = props

  const mainText = useColorModeValue('navy.700', 'white')
  const navbarPosition = 'fixed' as const
  const navbarFilter = 'none'
  const navbarBackdrop = 'blur(20px)'
  const navbarShadow = 'none'
  const navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)')
  const paddingX = '15px'
  const gap = '0px'
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition='center'
      backgroundSize='cover'
      backgroundColor='#6b52ff'
      transitionDelay='0s, 0s, 0s, 0s'
      transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
      transition-property='box-shadow, background-color, filter, border'
      transitionTimingFunction='linear, linear, linear, linear'
      alignItems={{ xl: 'center' }}
      display={secondary ? 'block' : 'flex'}
      maxH='110px'
      justifyContent={{ xl: 'center' }}
      lineHeight='25.6px'
      mx='auto'
      pb='8px'
      px={{
			  sm: paddingX,
			  md: '10px'
      }}
      ps={{
			  xl: '12px'
      }}
      pt='8px'
      borderBottom='solid white 2px'

      w='100%'
    >
      <Flex
        w='100%'
        flexDirection={{
				  sm: 'column',
				  md: 'row'
        }}
        alignItems={{ xl: 'center' }}
        mb={gap}
      >
        <Flex alignItems='center'>
          <Link href='/'>
            <Image src='/photon_logo_white.png' alt='Photon' maxH='70px' mt='10px' mb='10px' />
          </Link>
          <Text
            color={mainText}
            bg='inherit'
            borderRadius='inherit'
            fontSize='34px'
            mt='60PX'
            ml='90px'
          />
        </Flex>
        <Box ms='auto' w={{ sm: '100%', md: 'unset' }}>
          <AdminNavbarLinks
            onOpen={props.onOpen}
            secondary={props.secondary}
            fixed={props.fixed}
          />
        </Box>
      </Flex>
    </Box>
  )
}
